import config from '../config';
import Map from './Map';
// import {AlertRec} from './Map';

const Footer = () => {
  const { company, api } = config;
  // console.log('company= ', company)
  //alert(`REACT_APP_ITEMS_PER_PAGE=${REACT_APP_ITEMS_PER_PAGE}`)



  // console.log('Service data to be sent:', JSON.stringify(company, null, 2));
  // console.log('process.env.REACT_APP_API_URL= ', process.env.REACT_APP_API_URL)
  // console.log('process.env.REACT_APP_ITEMS_PER_PAGE= ', process.env.REACT_APP_ITEMS_PER_PAGE)

  // alert(`process.env.REACT_APP_ITEMS_PER_PAGE=${process.env.REACT_APP_ITEMS_PER_PAGE},\nprocess.env.REACT_APP_API_URL=${process.env.REACT_APP_API_URL} `)
  // alert(`Config>>> company.name=${company.name}\n api.baseURL=${api.baseURL}`)

  return (
    <footer className="bg-gray-900 text-white">
      {/* <div>{`process.env.REACT_APP_ITEMS_PER_PAGE=${process.env.REACT_APP_ITEMS_PER_PAGE},\nprocess.env.REACT_APP_API_URL=${process.env.REACT_APP_API_URL} `}</div>
      <div>{`Config>>> company.name=${company.name}\n api.baseURL=${api.baseURL}`}</div> */}

      {/* {JSON.stringify(company, null, 2)} */}

      <div className="w-full h-[300px]">
        <Map />
      </div>
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

          <div>
            <div className="flex items-center gap-2 mb-4">
              <img src="/logo.png" alt="Logo" className="h-12 w-12" />
              <h3 className="text-2xl font-bold mb-4">{company.name}</h3>
              {/* <span className="text-lg font-semibold">Company Name</span> */}
            </div>

            <p className="text-gray-400 mb-4">Your destination for premium beauty and wellness treatments.</p>
            <h3 className="text-lg font-semibold">{company.address}</h3>
          </div>

          <div>
            <h3 className="text-xl font-bold mb-4">Contact</h3>
            <div className="space-y-2">
              {/* <p>Aesthetic Solutions</p>
              <p>1st Floor, Mehran Traders Plaza, </p>
              <p>01 Canal Rd, Iqbal Avenue Phase 3, Block A </p>
              <p>Lahore, 54600</p> */}

              {company.address.split('|').map((line, index) => (
                <p key={index} className="leading-tight">
                  {line.trim()}
                </p>
              ))}

              <p>
                {company.city} {company.zip}
              </p>
              <p>
                {company.country}
              </p>

              <a
                href="https://g.co/kgs/s7vYH5X"
                target="_blank"
                rel="noopener noreferrer"
                className="text-accent hover:text-accent-dark transition-colors inline-flex items-center mt-2"
              >
                Get Directions
                <svg
                  className="w-4 h-4 ml-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Hours</h3>
            <div className="space-y-2">
              <p><span className="text-gray-400">Monday - Friday: </span> {company.businessHours.monFri}</p>
              <p><span className="text-gray-400">Saturday: </span> {company.businessHours.sat}</p>
              <p><span className="text-gray-400">Sunday: </span> {company.businessHours.sun}</p>

              <p className="mt-4 font-semibold text-accent">
                Emergency Contact: Available 24/7
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-800 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} WWW.BizApps.PK, All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 