import { motion } from 'framer-motion';

const PlaceholderImage = ({ className, text }) => {
  return (
    <motion.div 
      className={`bg-gray-200 flex items-center justify-center ${className}`}
      initial={{ opacity: 0.6 }}
      animate={{ opacity: 1 }}
      transition={{ repeat: Infinity, duration: 2 }}
    >
      <span className="text-gray-400 text-sm">{text || 'Image Coming Soon'}</span>
    </motion.div>
  );
};

export default PlaceholderImage; 