import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useServices } from '../hooks/useServices';
import LoadingSpinner from '../components/LoadingSpinner';
import BookNowButton from '../components/BookNowButton';
import ImageWithFallback from '../components/ImageWithFallback';

const ServiceProfile = () => {
  const { serviceId } = useParams();
  
  const { services, loading, error } = useServices();
  // alert(`\pages\ServiceProfile ---- slug or id serviceId= ${serviceId}`)

  if (loading) return <LoadingSpinner />;
  if (error) return <div>Error loading service details</div>;

  const service = services?.find(s => s.id.toString() === serviceId);
  if (!service) return <div>Service not found</div>;

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="relative h-[50vh] bg-gradient-to-r from-blue-600 to-blue-400">
        <div className="absolute inset-0">
          <ImageWithFallback
            src={service.image}
            alt={service.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black/50" />
        </div>
        <div className="relative container mx-auto px-4 h-full flex items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-white max-w-2xl"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-4">{service.title}</h1>
            {service.tag && (
              <span className="inline-block bg-accent/90 text-white px-4 py-2 rounded-full text-sm font-medium mb-4">
                {service.tag}
              </span>
            )}
            <p className="text-xl text-white/90">{service.description}</p>
          </motion.div>
        </div>
      </div>

      {/* Content Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-5 gap-12">
            {/* Details - now spans 3 columns */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="md:col-span-3"
            >
              <h2 className="text-3xl font-bold mb-6">About this Treatment</h2>
              <div className="prose max-w-none">
                <p className="text-gray-600 mb-8">{service.detail}</p>
                
                <div className="grid md:grid-cols-2 gap-8">
                  {/* Benefits */}
                  <div className="bg-gray-50 rounded-xl p-6">
                    <h3 className="text-xl font-semibold mb-4">Benefits</h3>
                    <ul className="space-y-3">
                      {service.benefits?.map((benefit, index) => (
                        <li key={index} className="flex items-start">
                          <span className="text-accent mr-2">✓</span>
                          <span className="text-gray-600">{benefit}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Procedure */}
                  <div className="bg-gray-50 rounded-xl p-6">
                    <h3 className="text-xl font-semibold mb-4">Procedure</h3>
                    <ol className="space-y-3">
                      {service.procedures?.map((step, index) => (
                        <li key={index} className="flex items-start">
                          <span className="text-accent font-bold mr-2">{index + 1}.</span>
                          <span className="text-gray-600">{step}</span>
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Booking section - spans 2 columns */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="md:col-span-2 space-y-6"
            >
              {/* Course of Treatment Card */}
              <div className="bg-gray-50 rounded-xl p-6">
                <h4 className="text-lg font-semibold mb-4">Course of Treatment</h4>
                <div className="space-y-4">
                    <div className="flex items-center">
                      <svg className="w-5 h-5 text-accent mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21l-7-5-7 5V5a2 2 0 012-2h10a2 2 0 012 2z" />
                      </svg>
                    <span className="text-gray-600">Treatment Type: {service.tag}</span>
                    </div>
                    <div className="flex items-center">
                  <svg className="w-5 h-5 text-accent mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                    <span className="text-gray-600">Duration: {service.duration} minutes</span>
                    </div>
                  </div>
                </div>

              {/* Booking Card */}
              <div className="bg-gray-50 rounded-xl p-6">
                <h3 className="text-2xl font-bold mb-4">Book this Treatment</h3>
                <p className="text-gray-600 mb-6">
                  Ready to experience the benefits of {service.title}? Book your appointment now.
                </p>
                <BookNowButton serviceId={service.id} className="w-full" />
                <p className="text-sm text-gray-500 mt-4 text-center">
                  Or call us at{' '}
                  <a href="tel:+1234567890" className="text-accent hover:text-accent-dark">
                    123-456-7890
                  </a>
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Treatment Gallery Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Treatment Gallery</h2>
          
          {/* Gallery Labels */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
            <div className="text-center">
              <h3 className="text-xl font-semibold text-accent">Before Treatment</h3>
                </div>
            <div className="text-center">
              <h3 className="text-xl font-semibold text-accent">During Treatment</h3>
              </div>
            <div className="text-center">
              <h3 className="text-xl font-semibold text-accent">After Treatment</h3>
                </div>
              </div>

          {/* Gallery Images */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {service.images?.map((imagePath, index) => {
              const captions = ['Before', 'During', 'After'];
              return (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="relative group overflow-hidden rounded-xl shadow-lg"
              >
                <div className="aspect-w-4 aspect-h-3">
                  <ImageWithFallback
                      src={imagePath}
                      alt={`${service.title} - ${captions[index]} Treatment`}
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-20 transition-opacity duration-300" />
                <div className="absolute bottom-4 left-4 bg-accent/90 text-white px-3 py-1 rounded-full text-sm">
                      {captions[index]}
                </div>
                </div>
              </motion.div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServiceProfile; 