import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import ServiceProfile from './pages/ServiceProfile';

import Footer from './components/Footer';

// Admin imports
import ScrollToTop from './components/ScrollToTop';
import { Toaster } from 'react-hot-toast';

import { Outlet, Link } from "react-router-dom";
import NoPage from './pages/NoPage';
import LocalServerApiTesting from './pages/LocalServerApiTesting';

const Layout = () => {
  return (
    <>
      {/* <nav>
        <ul>
          <li>            <Link to="/">Home</Link>          </li>
          <li>            <Link to="/blogs">Blogs</Link>          </li>
          <li>            <Link to="/contact">Contact</Link>          </li>
        </ul>
      </nav> */}

      <Navbar />
      <Outlet />
      <Footer />
    </>
  )
};

function App() {
  return (
    <>
      <Toaster position="top-right" />
      <div className="min-h-screen">
        <ScrollToTop />


        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />

            <Route path="services" element={<Services />} />
            <Route path="services/:serviceId" element={<ServiceProfile />} />

            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="admin" element={<div>Welcome! in the wonderful world of Music </div>} />
            <Route path="testAPI" element={<LocalServerApiTesting/>} />

            {/* <Route path="*" element={<div>Welcome! in the EMPTY world  </div>} /> */}
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>



        
      </div>
    </>
  );
}

export default App; 