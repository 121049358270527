import { motion } from 'framer-motion';
import { useState } from 'react';
import ImageWithFallback from './ImageWithFallback';

const galleryImages = [
  { src: '/images/gallery/gallery1.jpg', alt: 'Aesthetic Treatment 1' },
  { src: '/images/gallery/gallery2.jpg', alt: 'Aesthetic Treatment 2' },
  { src: '/images/gallery/gallery3.jpg', alt: 'Aesthetic Treatment 3' },
  { src: '/images/gallery/gallery4.jpg', alt: 'Aesthetic Treatment 4' },
  { src: '/images/gallery/gallery5.jpg', alt: 'Aesthetic Treatment 5' },
  { src: '/images/gallery/gallery6.jpg', alt: 'Aesthetic Treatment 6' },
  { src: '/images/gallery/gallery7.jpg', alt: 'Aesthetic Treatment 7' },
  { src: '/images/gallery/gallery8.jpg', alt: 'Aesthetic Treatment 8' },
  { src: '/images/gallery/gallery9.jpg', alt: 'Aesthetic Treatment 9' },
  { src: '/images/gallery/gallery10.jpg', alt: 'Aesthetic Treatment 10' },
  // { src: '/images/gallery/gallery11.jpg', alt: 'Aesthetic Treatment 11' },
  // { src: '/images/gallery/gallery12.jpg', alt: 'Aesthetic Treatment 12' },
  // { src: '/images/gallery/gallery13.jpg', alt: 'Aesthetic Treatment 13' },
  // { src: '/images/gallery/gallery14.jpg', alt: 'Aesthetic Treatment 14' },
  // { src: '/images/gallery/gallery15.jpg', alt: 'Aesthetic Treatment 15' }
];

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Treatment Gallery</h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Explore our collection of successful treatments and transformations
          </p>
        </motion.div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          {galleryImages.map((image, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="relative aspect-square rounded-xl overflow-hidden cursor-pointer group"
              onClick={() => setSelectedImage(image)}
            >
              <ImageWithFallback
                src={image.src}
                fallbackSrc="/images/gallery/gallery1.jpg"
                alt={image.alt}
                className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  className="text-white text-center p-4"
                >
                  <span className="text-sm font-medium">View Details</span>
                </motion.div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Image Modal */}
        {selectedImage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/90 p-4"
            onClick={() => setSelectedImage(null)}
          >
            <motion.img
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              src={selectedImage.src}
              alt={selectedImage.alt}
              className="max-h-[90vh] max-w-[90vw] object-contain rounded-lg"
            />
            <button
              className="absolute top-4 right-4 text-white hover:text-gray-300"
              onClick={() => setSelectedImage(null)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </motion.div>
        )}
      </div>
    </section>
  );
};

export default Gallery; 