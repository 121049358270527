import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import { motion } from 'framer-motion';
import 'swiper/css';
import 'swiper/css/pagination';

const testimonials = [
  {
    name: 'Sarah Johnson',
    role: 'Regular Client',
    image: '/images/testimonial/testimonial1.jpg',
    quote: 'Absolutely amazing experience! The staff is professional and the results exceeded my expectations.'
  },
  {
    name: 'Michael Brown',
    role: 'Client',
    image: '/images/testimonial/testimonial2.jpg',
    quote: 'The attention to detail and personalized care is outstanding. Highly recommend their services!'
  },
  {
    name: 'Emma Wilson',
    role: 'Regular Client',
    image: '/images/testimonial/testimonial3.jpg',
    quote: "I have been coming here for months and the results are consistently excellent."
  },
  {
    name: 'David Thompson',
    role: 'Client',
    image: '/images/testimonial/testimonial4.jpg',
    quote: 'Professional team, luxurious environment, and amazing results. Worth every penny!'
  },
  {
    name: 'Jennifer Lee',
    role: 'Regular Client',
    image: '/images/testimonial/testimonial5.jpg',
    quote: 'Outstanding service and amazing results. The team is very professional and caring.'
  },
  {
    name: 'Robert Clark',
    role: 'Client',
    image: '/images/testimonial/testimonial6.jpg',
    quote: 'Best aesthetic clinic in town! The results are natural and long-lasting.'
  },
  {
    name: 'Patricia White',
    role: 'Regular Client',
    image: '/images/testimonial/testimonial7.jpg',
    quote: 'Excellent treatments and wonderful staff. Highly recommended!'
  },
  {
    name: 'Thomas Anderson',
    role: 'Client',
    image: '/images/testimonial/testimonial8.jpg',
    quote: 'Very professional service with great attention to detail.'
  }
];

const Testimonials = () => {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Client Testimonials</h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Hear what our satisfied clients have to say
          </p>
        </motion.div>

        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          breakpoints={{
            640: { slidesPerView: 2 },
            1024: { slidesPerView: 3 }
          }}
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000 }}
          className="pb-12"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white p-8 rounded-xl shadow-lg"
              >
                  <div className="flex items-center mb-6">
                      <img
                        src={testimonial.image}
                        alt={testimonial.name}
                    className="w-16 h-16 rounded-full object-cover mr-4"
                      />
                    <div>
                    <h3 className="text-xl font-bold text-gray-900">{testimonial.name}</h3>
                    <p className="text-gray-600">{testimonial.role}</p>
                      </div>
                    </div>
                <p className="text-gray-600 italic">"{testimonial.quote}"</p>
              </motion.div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonials; 