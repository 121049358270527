import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
// import { useAuth } from '../contexts/AuthContext';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  // const { user } = useAuth();

  // Handle scroll
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav
      className={`fixed w-full z-50 transition-all duration-300 ${
        // isScrolled ? 'bg-white shadow-md' : 'bg-transparent'
        isScrolled ? 'bg-white shadow-md bg-opacity-90 ' : 'bg-white-600 bg-opacity-25 '
        }`}
    >
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link
            to="/"
            // className={`text-xl font-bold ${isScrolled ? 'text-gray-900' : 'text-white'
            className={`text-xl font-bold ${isScrolled ? 'text-gray-900' : 'text-gray-300'
              }`}
          >
            <div className="flex items-center gap-2">
              <img src="/logo.png" alt="Logo" className="h-12 w-12" />
              Aesthetics Solutions
              {/* <span className="text-lg font-semibold">Company Name</span> */}
            </div>
          </Link>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-6">
            {['Home', 'Services', 'About', 'Contact'].map((item) => (
              <Link
                key={item}
                // to={`/${item.toLowerCase()}`}
                to={`${item.toLowerCase()}`}
                className={`${isScrolled
                    ? 'text-gray-700 hover:text-accent'
                    // : 'text-white hover:text-white/80'
                    : 'text-gray-300 hover:text-green/80'
                  } transition-colors text-sm`}
              >
                {item}
              </Link>
            ))}
            <div className="flex items-center gap-4">
              <Link
                to="/booking"
                className={`px-6 py-2 rounded-lg font-semibold transition-all duration-300
                  ${isScrolled
                    ? 'bg-accent text-white hover:bg-accent-dark'
                    : 'bg-white text-accent hover:bg-gray-100'
                  }`}
              >
                Book Now
              </Link>
            </div>

            {/* {user?.isAdmin && (
              <Link
              to="/admin"
                className="text-gray-600 hover:text-gray-900"
            >
                Admin Panel
              </Link>
            )} */}
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <svg
              className={`w-6 h-6 ${isScrolled ? 'text-gray-900' : 'text-white'}`}
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMobileMenuOpen ? (
                <path d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="md:hidden bg-white"
            >
              <div className="py-4 space-y-4">
                {['Home', 'Services', 'About', 'Contact'].map((item) => (
                  <Link
                    key={item}
                    to={`/${item.toLowerCase()}`}
                    className="block px-4 py-2 text-gray-700 hover:text-accent"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    {item}
                  </Link>
                ))}
                <Link
                  to="/booking"
                  className="block mx-4 px-6 py-2 bg-accent text-white rounded-lg text-center"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Book Now
                </Link>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
};

export default Navbar; 