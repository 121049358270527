import React, { useState } from 'react';
import { FaLinkedin, FaInstagram, FaTwitter } from 'react-icons/fa';
import { motion } from 'framer-motion';
import ImageWithFallback from './ImageWithFallback';
// import TeamMemberModal from './TeamMemberModal';
import DoctorProfileModal from './DoctorProfileModal';
import { teamMembers } from '../data/team';

const TeamMemberCard = ({ member, index, onDoctorClick }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay: index * 0.1 }}
      className={`bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 group
        ${member.activeProfile ? "cursor-pointer" : ""}`}

        onClick={() => {
        if (member.activeProfile) {
          onDoctorClick(member);
        }
      }}
    >
      <div className="relative h-80 overflow-hidden">
        <ImageWithFallback
          src={member.image}
          fallback="/images/team/placeholder.jpg"
          alt={member.name}
          className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-700"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        
        {member.activeProfile && (
          <div className="absolute bottom-4 left-4 text-white opacity-0 group-hover:opacity-100 transition-all duration-300 flex items-center gap-2">
            <span className="text-sm font-medium">View Profile</span>
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        )}
      </div>
      <div className="p-6">
        <h3 className="text-xl font-bold text-gray-900 group-hover:text-accent transition-colors">
          {member.name}
        </h3>
        <p className="text-accent font-medium mb-2">{member.role}</p>
        <p className="text-gray-600 mb-4">{member.description}</p>
        
        <div className="flex items-center justify-between">
          <div className="flex gap-4">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className="text-gray-400 hover:text-accent transition-colors"
            >
              <FaLinkedin className="w-5 h-5" />
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className="text-gray-400 hover:text-accent transition-colors"
            >
              <FaInstagram className="w-5 h-5" />
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className="text-gray-400 hover:text-accent transition-colors"
            >
              <FaTwitter className="w-5 h-5" />
            </motion.button>
          </div>

          <motion.button
            whileHover={{ 
              scale: 1.02,
              backgroundColor: "var(--color-accent-dark)" 
            }}
            whileTap={{ scale: 0.98 }}
            className="px-4 py-2 bg-accent text-white rounded-lg hover:bg-gradient-to-r hover:from-accent hover:to-accent-dark 
            transition-all duration-300 text-sm shadow-md hover:shadow-lg transform"
            onClick={(e) => {
              e.stopPropagation();
              window.location.href = '/booking';
            }}
          >
            Book Now
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
};

const Team = () => {
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  return (
    <section id="team" className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <motion.div 
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Meet Our Team</h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Our highly qualified team of professionals is dedicated to providing you with the best care
          </p>
        </motion.div>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {teamMembers.map((member, index) => (
            <TeamMemberCard
              key={member.id}
              member={member}
              index={index}
              onDoctorClick={setSelectedDoctor}
            />
          ))}
        </div>

        {/* Doctor Profile Modal */}
        <DoctorProfileModal
          doctor={selectedDoctor}
          isOpen={!!selectedDoctor}
          onClose={() => setSelectedDoctor(null)}
        />
      </div>
    </section>
  );
};

export default Team; 