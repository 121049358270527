const config = {
  api: {
    baseURL: process.env.REACT_APP_API_URL,
    itemsPerPage: parseInt(process.env.REACT_APP_ITEMS_PER_PAGE || '10', 10)
  },
  company: {
    name: process.env.REACT_APP_COMPANY_NAME,
    currency: {
      code: process.env.REACT_APP_CURRENCY || 'PKR',
      symbol: process.env.REACT_APP_CURRENCY_SYMBOL || 'Rs.'
    },
    address: process.env.REACT_APP_COMPANY_ADDRESS,
    city: process.env.REACT_APP_COMPANY_CITY,
    country: process.env.REACT_APP_COMPANY_COUNTRY,
    zip: process.env.REACT_APP_COMPANY_ZIP,
    phone: process.env.REACT_APP_COMPANY_PHONE,
    email: process.env.REACT_APP_COMPANY_EMAIL,
    businessHours: {
      monFri: process.env.REACT_APP_BUSINESS_HOURS_MON_FRI,
      sat: process.env.REACT_APP_BUSINESS_HOURS_SAT,
      sun: process.env.REACT_APP_BUSINESS_HOURS_SUN
    },
    social: {
      facebook: process.env.REACT_APP_SOCIAL_FACEBOOK,
      instagram: process.env.REACT_APP_SOCIAL_INSTAGRAM,
      twitter: process.env.REACT_APP_SOCIAL_TWITTER
    },
    maps: {
      link: process.env.REACT_APP_GOOGLE_MAPS_LINK,
      embedUrl: process.env.REACT_APP_MAP_EMBED_URL
    }
  }
};

export default config; 