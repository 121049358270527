// import Hero from '../components/Hero';
import Hero from '../components/SectionHero';
import Services from '../components/Services';
import Features from '../components/Features';
import WhyChooseUs from '../components/WhyChooseUs';
import Gallery from '../components/Gallery';
import Testimonials from '../components/Testimonials';
import BookingCTASection from '../components/BookingCTASection';
import Team from '../components/Team';
// Import other components as needed

const Home = ({ services }) => {
  return (
    <main>
      <Hero />
      <Services services={services} />
      <Features />
      <WhyChooseUs />
      <Team />
      <Gallery />
      <Testimonials />
      <BookingCTASection />

      {/* Other sections */}
    </main>
  );
};

export default Home; 