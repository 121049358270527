console.log('Loading services data file');

export const services = [
  {
    id: 1,
    sid: 'botox-cosmetic-treatment',
    title: 'Botox Cosmetic Treatment',
    tag: 'Reduce Fine Lines and Wrinkles with Premium Botox',
    description: 'FDA-approved injectable treatment that temporarily reduces moderate to severe facial lines by relaxing underlying muscles, resulting in smoother, younger-looking skin.',
    detail: 'Botox works by blocking nerve signals in the muscles where it is injected. When those nerve signals are interrupted, the affected muscle is temporarily paralyzed or frozen. Without movement of these selected muscles in the face, certain wrinkles are reduced, softened, or even removed.',
    benefits: [
      'Reduces appearance of fine lines and wrinkles',
      'Prevents formation of new wrinkles',
      'Quick 10-15 minute procedure',
      'Minimal downtime and recovery',
      'Natural-looking results',
      'FDA-approved safe treatment'
    ],
    procedures: [
      'Initial consultation and facial analysis',
      'Marking of injection points',
      'Cleansing of treatment areas',
      'Precise Botox injection',
      'Post-treatment care instructions',
      'Follow-up assessment after 2 weeks'
    ],
    category: 'Injectables',
    image: "/images/services/botox/botox.jpg",
    images: [
      "/images/services/botox/botox-1.jpg",
      "/images/services/botox/botox-2.jpg",
      "/images/services/botox/botox-3.jpg"
    ],
    price: 299,
    duration: 30
  },
  {
    id: 2,
    sid: 'dermal-fillers',
    title: 'Dermal Fillers',
    tag: 'Restore Volume and Enhance Facial Contours',
    description: 'Premium hyaluronic acid fillers to restore lost volume, enhance facial contours, and create a more youthful appearance with natural-looking results.',
    detail: 'Dermal fillers are gel-like substances injected beneath the skin to restore lost volume, smooth lines, soften creases, or enhance facial contours. Most popular fillers contain hyaluronic acid, a naturally occurring substance in your skin.',
    benefits: [
      'Immediate volume enhancement',
      'Smooths deep wrinkles and folds',
      'Enhances lip volume and shape',
      'Natural-looking results',
      'Long-lasting results (6-18 months)',
      'Minimal recovery time'
    ],
    procedures: [
      'Facial assessment and mapping',
      'Application of topical anesthetic',
      'Strategic filler placement',
      'Gentle massage for optimal distribution',
      'Result assessment and adjustments',
      'Aftercare instructions'
    ],
    category: 'Injectables',
    image: "/images/services/threadlift/threadlift.jpg",
    images: [
      "/images/services/threadlift/threadlift-1.jpg",
      "/images/services/threadlift/threadlift-2.jpg",
      "/images/services/threadlift/threadlift-3.jpg"
    ],
    price: 549,
    duration: 45
  },
  {
    id: 3,
    sid: 'laser-hair-removal',
    title: 'Laser Hair Removal',
    tag: 'Permanent Hair Reduction with Advanced Technology',
    description: 'State-of-the-art laser treatment for permanent hair reduction on face and body, suitable for all skin types with minimal discomfort.',
    detail: 'Using advanced laser technology, this treatment targets hair follicles with precise light pulses, effectively disabling future hair growth while protecting surrounding skin. Results improve with each session.',
    benefits: [
      'Permanent hair reduction',
      'Suitable for all skin types',
      'Treats multiple areas',
      'Prevents ingrown hairs',
      'Smoother skin texture',
      'Cost-effective long-term solution'
    ],
    procedures: [
      'Skin type assessment',
      'Treatment area preparation',
      'Protective eye wear application',
      'Systematic laser application',
      'Cooling gel application',
      'Post-treatment care instructions'
    ],
    category: 'Laser',
    image: "/images/services/laserhairremoval/laserhairremoval.jpg",
    images: [
      "/images/services/laserhairremoval/laserhairremoval-1.jpg",
      "/images/services/laserhairremoval/laserhairremoval-2.jpg",
      "/images/services/laserhairremoval/laserhairremoval-3.jpg"
    ],
    price: 249,
    duration: 60
  },
  {
    id: 4,
    sid: 'hydrafacial-treatment',
    title: 'HydraFacial Treatment',
    tag: 'Deep Cleansing and Hydration for Radiant Skin',
    description: 'Advanced facial treatment combining cleansing, exfoliation, extraction, and hydration for immediate results with no downtime.',
    detail: 'HydraFacial uses patented technology to cleanse, extract, and hydrate skin. The treatment removes dead skin cells and impurities while simultaneously delivering moisturizing serums into the skin.',
    benefits: [
      'Deep pore cleansing',
      'Immediate skin brightening',
      'Improved skin texture',
      'Enhanced hydration',
      'Reduced fine lines',
      'Even skin tone'
    ],
    procedures: [
      'Skin analysis and consultation',
      'Deep cleansing and exfoliation',
      'Gentle extraction process',
      'Hydration serum application',
      'LED light therapy',
      'Protective moisturizer application'
    ],
    category: 'Facial',
    image: "/images/services/hydrafacial/hydrafacial.jpg",
    images: [
      "/images/services/hydrafacial/hydrafacial-1.jpg",
      "/images/services/hydrafacial/hydrafacial-2.jpg",
      "/images/services/hydrafacial/hydrafacial-3.jpg"
    ],
    price: 159,
    duration: 60
  },
  {
    id: 5,
    sid: 'microneedling-collagen',
    title: 'Microneedling with Collagen Induction',
    tag: 'Stimulate Natural Collagen Production',
    description: 'Advanced skin rejuvenation treatment using fine needles to create micro-channels, stimulating natural collagen production for firmer, younger-looking skin.',
    detail: "Microneedling creates controlled micro-injuries that stimulate your body's natural wound healing process, while minimizing cellular damage. This results in collagen and elastin production.",
    benefits: [
      'Reduces fine lines and wrinkles',
      'Improves skin texture',
      'Minimizes pore size',
      'Treats acne scars',
      'Enhances product absorption',
      'Natural collagen stimulation'
    ],
    procedures: [
      'Skin cleansing and preparation',
      'Topical anesthetic application',
      'Systematic microneedling treatment',
      'Serum infusion',
      'Calming mask application',
      'Post-care instructions'
    ],
    category: 'Anti-Aging',
    image: "/images/services/microneedling/microneedling.jpg",
    images: [
      "/images/services/microneedling/microneedling-1.jpg",
      "/images/services/microneedling/microneedling-2.jpg",
      "/images/services/microneedling/microneedling-3.jpg"
    ],
    price: 349,
    duration: 75
  },
  {
    id: 6,
    sid: 'coolsculpting-fat-reduction',
    title: 'CoolSculpting Fat Reduction',
    tag: 'Non-Surgical Fat Reduction Technology',
    description: 'FDA-cleared fat-freezing procedure that uses controlled cooling to eliminate stubborn fat that resists diet and exercise.',
    detail: 'CoolSculpting technology safely delivers precisely controlled cooling to gently and effectively target fat cells underneath the skin. Treated fat cells are crystallized (frozen), then die and are naturally processed and eliminated by the body.',
    benefits: [
      'No surgical intervention',
      'Permanent fat reduction',
      'No downtime required',
      'Natural-looking results',
      'Targets specific areas',
      'FDA-cleared treatment'
    ],
    procedures: [
      'Initial assessment and marking',
      'Gel pad application',
      'CoolSculpting device placement',
      'Controlled cooling process',
      'Post-treatment massage',
      'Progress documentation'
    ],
    category: 'Body',
    image: "/images/services/contouring/contouring.jpg",
    images: [
      "/images/services/contouring/contouring-1.jpg",
      "/images/services/contouring/contouring-2.jpg",
      "/images/services/contouring/contouring-3.jpg"
    ],
    price: 899,
    duration: 90
  },
  {
    id: 7,
    sid: 'prp-hair-restoration',
    title: 'PRP Hair Restoration',
    tag: 'Natural Hair Growth Stimulation',
    description: 'Advanced treatment using platelet-rich plasma from your own blood to stimulate hair follicles and promote natural hair growth.',
    detail: 'PRP therapy for hair loss involves drawing blood, processing it to concentrate the platelets, and injecting the growth-factor-rich plasma into the scalp to stimulate dormant hair follicles.',
    benefits: [
      'Stimulates natural hair growth',
      'Uses your own blood plasma',
      'No artificial chemicals',
      'Minimal downtime',
      'Long-lasting results',
      'Treats various hair loss patterns'
    ],
    procedures: [
      'Blood draw and processing',
      'Scalp preparation and cleaning',
      'PRP concentration preparation',
      'Strategic injection placement',
      'Scalp massage and stimulation',
      'Follow-up treatment planning'
    ],
    category: 'Hair',
    image: "/images/services/rejuvenation/rejuvenation.jpg",
    images: [
      "/images/services/rejuvenation/rejuvenation-1.jpg",
      "/images/services/rejuvenation/rejuvenation-2.jpg",
      "/images/services/rejuvenation/rejuvenation-3.jpg"
    ],
    price: 799,
    duration: 60
  },
  {
    id: 8,
    sid: 'chemical-peel-treatment',
    title: 'Medical-Grade Chemical Peel',
    tag: 'Reveal Fresh, Renewed Skin',
    description: 'Professional-strength chemical peel that removes damaged skin layers to reveal brighter, younger-looking skin with improved texture.',
    detail: 'This advanced chemical peel uses medical-grade solutions to exfoliate dead skin cells and stimulate cell renewal. Different strengths available to target specific skin concerns.',
    benefits: [
      'Reduces hyperpigmentation',
      'Improves skin texture',
      'Minimizes fine lines',
      'Treats acne scarring',
      'Stimulates collagen production',
      'Customizable treatment strength'
    ],
    procedures: [
      'Skin analysis and preparation',
      'Deep cleansing process',
      'Peel solution application',
      'Timed treatment monitoring',
      'Neutralizing and soothing',
      'Post-peel care instructions'
    ],
    category: 'Facial',
    image: "/images/services/peel/peel.jpg",
    images: [
      "/images/services/peel/peel-1.jpg",
      "/images/services/peel/peel-2.jpg",
      "/images/services/peel/peel-3.jpg"
    ],
    price: 249,
    duration: 45
  },
  {
    id: 9,
    sid: 'radiofrequency-skin-tightening',
    title: 'RF Skin Tightening',
    tag: 'Non-Surgical Skin Firming Solution',
    description: 'Advanced radiofrequency treatment that tightens skin and reduces wrinkles by stimulating collagen production deep within the skin.',
    detail: 'Radiofrequency energy penetrates deep into the skin layers to heat the tissue and stimulate collagen production. This results in firmer, tighter skin with improved texture and reduced sagging.',
    benefits: [
      'Non-invasive treatment',
      'Immediate skin tightening',
      'Progressive results',
      'No downtime required',
      'Suitable for face and body',
      'Natural-looking results'
    ],
    procedures: [
      'Skin cleansing and prep',
      'Coupling gel application',
      'RF energy delivery',
      'Temperature monitoring',
      'Multiple pass technique',
      'Cooling and moisturizing'
    ],
    category: 'Anti-Aging',
    image: "/images/services/skintightening/skintightening.jpg",
    images: [
      "/images/services/skintightening/skintightening-1.jpg",
      "/images/services/skintightening/skintightening-2.jpg",
      "/images/services/skintightening/skintightening-3.jpg"
    ],
    price: 449,
    duration: 60
  },
  {
    id: 10,
    sid: 'laser-skin-resurfacing',
    title: 'Fractional Laser Resurfacing',
    tag: 'Advanced Skin Renewal Technology',
    description: 'State-of-the-art laser treatment that creates micro-thermal zones to stimulate new collagen and reveal fresher, younger-looking skin.',
    detail: 'This advanced laser treatment creates thousands of microscopic treatment zones in the skin, stimulating the production of new collagen while leaving surrounding tissue intact for rapid healing.',
    benefits: [
      'Reduces wrinkles and scars',
      'Improves skin texture',
      'Treats sun damage',
      'Minimizes pore size',
      'Stimulates collagen',
      'Long-lasting results'
    ],
    procedures: [
      'Skin preparation and cleaning',
      'Topical anesthetic application',
      'Laser treatment delivery',
      'Multiple pass technique',
      'Cooling mask application',
      'Aftercare instructions'
    ],
    category: 'Laser',
    image: "/images/services/dermaplaning/dermaplaning.jpg",
    images: [
      "/images/services/dermaplaning/dermaplaning-1.jpg",
      "/images/services/dermaplaning/dermaplaning-2.jpg",
      "/images/services/dermaplaning/dermaplaning-3.jpg"
    ],
    price: 999,
    duration: 90
  },
  {
    id: 11,
    sid: 'body-contouring-treatment',
    title: 'Ultra Body Contouring',
    tag: 'Advanced Body Sculpting Technology',
    description: 'Non-invasive body contouring treatment combining ultrasound and radiofrequency to reduce fat and tighten skin simultaneously.',
    detail: 'This dual-technology treatment targets stubborn fat deposits while tightening loose skin. The combination of ultrasound and RF energy provides comprehensive body sculpting results.',
    benefits: [
      'Reduces stubborn fat',
      'Tightens loose skin',
      'No downtime required',
      'Comfortable treatment',
      'Multiple areas treated',
      'Long-lasting results'
    ],
    procedures: [
      'Body assessment and marking',
      'Ultrasound gel application',
      'Dual-energy delivery',
      'Systematic area treatment',
      'Post-treatment massage',
      'Progress measurements'
    ],
    category: 'Body',
    image: "/images/services/laserhairremoval/laserhairremoval.jpg",
    images: [
      "/images/services/laserhairremoval/laserhairremoval-1.jpg",
      "/images/services/laserhairremoval/laserhairremoval-2.jpg",
      "/images/services/laserhairremoval/laserhairremoval-3.jpg"
    ],
    price: 599,
    duration: 75
  },
  {
    id: 12,
    sid: 'lip-enhancement-filler',
    title: 'Premium Lip Enhancement',
    tag: 'Natural-Looking Lip Augmentation',
    description: 'Advanced lip enhancement using premium dermal fillers to create fuller, naturally beautiful lips with precise volume control.',
    detail: 'Using the latest hyaluronic acid fillers specifically designed for the lips, this treatment provides natural-looking volume enhancement while maintaining proper lip proportion and shape.',
    benefits: [
      'Natural-looking results',
      'Customizable volume',
      'Immediate results',
      'Long-lasting enhancement',
      'Reversible if desired',
      'Minimal downtime'
    ],
    procedures: [
      'Lip assessment and planning',
      'Topical numbing application',
      'Strategic filler placement',
      'Shape refinement',
      'Symmetry assessment',
      'Aftercare instructions'
    ],
    category: 'Injectables',
    image: "/images/services/peel/peel.jpg",
    images: [
      "/images/services/peel/peel-1.jpg",
      "/images/services/peel/peel-2.jpg",
      "/images/services/peel/peel-3.jpg"
    ],
    price: 549,
    duration: 45
  }
];

console.log('Total services:', services.length);

export const categories = ["All", "Face", "Body", "Skin"];

export const getServiceById = (id) => {
  return services.find(service => service.id === id);
};

export const getServiceBySlug = (slug) => {
  return services.find(service =>
    service.title.toLowerCase().replace(/[^a-z0-9]+/g, '-') === slug
  );
};

export const filterServicesByCategory = (category) => {
  if (category === "All") return services;
  return services.filter(service => service.category === category);
}; 