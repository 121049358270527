import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ImageWithFallback from './ImageWithFallback';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import BookNowButton from './BookNowButton';

const heroSlides = [
  {
    image: "/images/slider/slide1.jpg",
    title: "Advanced Aesthetic Treatments",
    subtitle: "Experience the latest in beauty technology"
  },
  {
    image: "/images/slider/slide2.jpg",
    title: "Expert Skincare Solutions",
    subtitle: "Personalized treatments for your unique needs"
  },
  {
    image: "/images/slider/slide3.jpg",
    title: "Rejuvenating Therapies",
    subtitle: "Restore your natural beauty"
  },
  {
    image: "/images/slider/slide4.jpg",
    title: "Professional Care",
    subtitle: "Trust our experienced specialists"
  },
  {
    image: "/images/slider/slide5.jpg",
    title: "Transformative Results",
    subtitle: "See the difference with our treatments"
  },
  {
    image: "/images/slider/slide6.jpg",
    title: "Luxury Experience",
    subtitle: "Premium care in a relaxing environment"
  },
  {
    image: "/images/slider/slide7.jpg",
    title: "Cutting-Edge Technology",
    subtitle: "The latest in aesthetic innovation"
  },
  {
    image: "/images/slider/slide8.jpg",
    title: "Natural Enhancement",
    subtitle: "Subtle improvements for lasting confidence"
  }
];

const Hero = () => {
  return (
    <section className="relative h-screen flex items-center">
      <div className="absolute inset-0 z-0">
        <ImageWithFallback
          src="/images/hero/hero-bg.jpg"
          fallback="/images/hero/fallback-hero.jpg"
          alt="Hero background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/50" />
      </div>

      <div className="container mx-auto px-4 z-10 text-white">
      <Swiper
        modules={[Navigation, Pagination, Autoplay, EffectFade]}
        effect="fade"
        speed={1000}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className} bg-white/50 hover:bg-white"></span>`;
          },
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop
        className="absolute inset-0"
      >
        {heroSlides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="relative h-screen">
              {/* Background Image */}
              <div className="absolute inset-0">
                <div className="absolute inset-0 bg-gradient-to-r from-black/70 via-black/60 to-black/70 z-10" />
                <ImageWithFallback
                  src={slide.image}
                  fallbackSrc="/images/slider/slide1.jpg"
                  alt={slide.title}
                  className="w-full h-full object-cover"
                  loading={index === 0 ? "eager" : "lazy"}
                />
              </div>

              {/* Content */}
              <div className="relative z-20 h-full flex items-center justify-center">
                <div className="container mx-auto px-4">
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className="max-w-3xl mx-auto text-center"
                  >
                    <motion.h1 
                      className="text-5xl md:text-6xl font-bold text-white mb-6"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.8, delay: 0.2 }}
                    >
                      {slide.title}
                    </motion.h1>
                    
                    <motion.p 
                      className="text-xl text-white/90 mb-8"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.8, delay: 0.4 }}
                    >
                      {slide.subtitle}
                    </motion.p>

                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.8, delay: 0.6 }}
                      className="flex gap-4 justify-center"
                    >
                      <BookNowButton className="px-8 py-3 text-lg" />
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="px-8 py-3 text-lg border-2 border-white text-white rounded-lg hover:bg-white/10 transition-colors"
                        onClick={() => document.getElementById('services').scrollIntoView({ behavior: 'smooth' })}
                      >
                        Our Services
                      </motion.button>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom Navigation Buttons */}
      <div className="swiper-button-prev !text-white !w-12 !h-12 !bg-black/30 rounded-full hover:!bg-black/50 transition-colors" />
      <div className="swiper-button-next !text-white !w-12 !h-12 !bg-black/30 rounded-full hover:!bg-black/50 transition-colors" />
      </div>
    </section>
  );
};

export default Hero; 