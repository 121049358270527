import { motion } from 'framer-motion';
import { FaUserMd, FaClock, FaHospital, FaAward } from 'react-icons/fa';

const reasons = [
  {
    icon: <FaUserMd className="w-12 h-12 text-accent" />,
    title: 'Expert Specialists',
    description: 'Our team of certified professionals brings years of experience in aesthetic treatments'
  },
  {
    icon: <FaClock className="w-12 h-12 text-accent" />,
    title: 'Flexible Scheduling',
    description: 'Early morning and evening appointments available to fit your busy lifestyle'
  },
  {
    icon: <FaHospital className="w-12 h-12 text-accent" />,
    title: 'Luxury Environment',
    description: 'Relax in our state-of-the-art facility designed for your comfort and tranquility'
  },
  {
    icon: <FaAward className="w-12 h-12 text-accent" />,
    title: 'Proven Results',
    description: 'Thousands of satisfied clients and award-winning treatments'
  }
];

const WhyChooseUs = () => {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Why Choose Us</h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Experience excellence in aesthetic treatments
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 gap-8">
          {reasons.map((reason, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="bg-gray-50 p-8 rounded-xl"
            >
              <div className="flex items-center mb-4">
                {reason.icon}
              </div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">{reason.title}</h3>
              <p className="text-gray-600">{reason.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs; 