import React, { useState } from 'react';
import PlaceholderImage from './PlaceholderImage';

const ImageWithFallback = ({ src, alt, className, ...props }) => {
  const [error, setError] = useState(false);
  const fallbackImage = '/images/placeholder.jpg'; // Add a placeholder image to your public folder

  const handleError = () => {
    if (!error) {
      setError(true);
    }
  };

  if (error || !src) {
    return <PlaceholderImage className={className} text={alt} />;
  }

  return (
    <img
      src={error ? fallbackImage : src}
      alt={alt}
      className={className}
      onError={handleError}
      {...props}
    />
  );
};

export default ImageWithFallback; 