import { motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import SearchBar from './StaticSectionServices/SearchBar';
import CategoryFilter from './StaticSectionServices/CategoryFilter';
import { categories } from '../data/services';
import LoadingSpinner from './StaticSectionServices/LoadingSpinner';
import BookingCTA from './BookingCTA';
import { useServices } from '../hooks/useServices';

export default function Services() {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const { services, loading, error } = useServices();

  if (loading) return <LoadingSpinner />;
  if (error) return null;
  if (!services) return null;

  const filteredServices = services
    .filter(service => 
      selectedCategory === 'All' || service.category === selectedCategory
    )
    .filter(service =>
      (service.title?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
      (service.description?.toLowerCase() || '').includes(searchQuery.toLowerCase())
    );

  return (
    <section id="services" className="py-20">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Our Services</h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto mb-8">
            Discover our comprehensive range of aesthetic treatments
          </p>

          {/* Search and Filter */}
          <div className="flex flex-col md:flex-row items-center justify-center gap-4 mb-8">
            <SearchBar 
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Search services..."
            />
            <CategoryFilter 
              categories={categories}
              selected={selectedCategory}
              onSelect={setSelectedCategory}
            />
          </div>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredServices.map((service, index) => (
            <motion.div
              key={service.id || index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.05 }}
              whileHover={{ 
                y: -5,
                scale: 1.01,
                backgroundColor: "rgb(251, 251, 254)",
                transition: { duration: 0.2 }
              }}
              className="group bg-white rounded-xl shadow-lg hover:shadow-2xl transition-all duration-200"
            >
              {/* Image Container */}
              <div className="relative aspect-[4/3] overflow-hidden rounded-t-xl">
                <img
                  src={service.image}
                  alt={service.title}
                  className="w-full h-full object-cover transform group-hover:scale-115 transition-transform duration-700"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-500" />
              </div>

              {/* Content */}
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-900 group-hover:text-accent transition-colors duration-300 mb-3">
                  {service.title}
                </h3>
                <p className="text-gray-600 mb-4 group-hover:text-gray-700 transition-colors duration-300">
                  {service.tag}
                </p>
                <p className="text-sm text-gray-500 mb-6 group-hover:text-gray-600 transition-colors duration-300">
                  {service.description}
                </p>
                <div className="flex justify-between items-center gap-4">
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="flex-1"
                  >
                    <Link
                      to={`/services/${service.id}`}
                      className="inline-block w-full text-center px-4 py-2 bg-white border-2 border-accent text-accent rounded-lg font-semibold 
                        hover:bg-gradient-to-r hover:from-accent hover:to-accent-light
                        hover:border-transparent hover:text-blue-600
                        transform transition-all duration-300 ease-in-out hover:shadow-lg"
                    >
                      Discover Benefits
                    </Link>
                  </motion.div>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="flex-1"
                  >
                    <Link
                      to="/booking"
                      className="inline-block w-full text-center px-4 py-2 bg-accent text-white rounded-lg font-semibold 
                        hover:bg-accent-dark
                        transform transition-all duration-300 shadow-lg hover:shadow-xl"
                    >
                      Book Now
                    </Link>
                  </motion.div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
} 