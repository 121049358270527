export const teamMembers = [
  {
    id: 1,
    name: "Dr. James Wilson",
    role: "Senior Dermatologist",
    image: "/images/Team/james-wilson.jpg",
    description: 'With over 15 years of experience in aesthetic medicine',
    activeProfile: true,
    specialization: "Cosmetic Dermatology & Laser Treatments",
    qualifications: [
      "MD - Dermatology",
      "Board Certified in Dermatology",
      "Fellowship in Cosmetic Dermatology",
      "Member of American Academy of Dermatology"
    ],
    experience: "15+ years of experience in aesthetic medicine and dermatology",
    specialties: [
      "Anti-aging Treatments",
      "Laser Therapy",
      "Dermal Fillers",
      "Chemical Peels",
      "Skin Cancer Screening"
    ]
  },
  {
    id: 2,
    name: 'Dr. Sarah Mitchell',
    role: 'Lead Aesthetic Specialist',
    image: '/images/Team/sarah-mitchell.jpg',
    description: 'With over 15 years of experience in aesthetic medicine',
    activeProfile: true,
    specialization: "Advanced Aesthetic Medicine",
    qualifications: [
      "MD - Aesthetic Medicine",
      "Board Certified in Aesthetic Medicine",
      "Advanced Training in Injectables",
      "Member of American Society for Aesthetic Plastic Surgery"
    ],
    experience: "12+ years specializing in non-surgical facial rejuvenation",
    specialties: [
      "Botox & Dermal Fillers",
      "Thread Lifts",
      "PRP Therapy",
      "Skin Rejuvenation",
      "Non-surgical Face Lifts"
    ]
  },
  {
    id: 3,
    name: 'Emma Thompson',
    role: 'Aesthetic Nurse',
    image: '/images/Team/emma-thompson.jpg',
    description: 'Expert in non-surgical aesthetic treatments',
    activeProfile: true,
    specialization: "Advanced Aesthetic Nursing",
    qualifications: [
      "BSN - Nursing",
      "Certified Aesthetic Nurse Specialist",
      "Advanced Injectable Certification",
      "Member of American Society of Plastic Surgical Nurses"
    ],
    experience: "8+ years in aesthetic nursing and patient care",
    specialties: [
      "Injectable Treatments",
      "Laser Treatments",
      "Chemical Peels",
      "Microneedling",
      "Patient Care Management"
    ]
  },
  {
    id: 4,
    name: 'Dr. Michael Chen',
    role: 'Cosmetic Surgeon',
    image: '/images/Team/michael-chen.jpg',
    description: 'Specialized in facial aesthetics and rejuvenation',
    activeProfile: true,
    specialization: "Facial Plastic Surgery & Aesthetics",
    qualifications: [
      "MD - Plastic Surgery",
      "Fellowship in Facial Plastic Surgery",
      "Board Certified in Plastic Surgery",
      "Member of American Academy of Facial Plastic Surgery"
    ],
    experience: "10+ years of surgical and non-surgical aesthetic procedures",
    specialties: [
      "Facial Contouring",
      "Rhinoplasty",
      "Advanced Injectables",
      "Laser Resurfacing",
      "Facial Reconstruction"
    ]
  },
  {
    id: 5,
    name: 'Lisa Rodriguez',
    role: 'Beauty Therapist',
    image: '/images/Team/lisa-rodriguez.jpg',
    description: 'Expert in skincare and beauty treatments',
    activeProfile: true,
    specialization: "Advanced Skincare & Beauty Therapy",
    qualifications: [
      "Licensed Aesthetician",
      "Advanced Skincare Specialist",
      "Medical Aesthetics Certification",
      "Certified in Advanced Beauty Treatments"
    ],
    experience: "9+ years in medical spa and skincare treatments",
    specialties: [
      "Advanced Facials",
      "Skin Analysis",
      "Chemical Peels",
      "Body Treatments",
      "Customized Skincare Plans"
    ]
  },
  {
    id: 6,
    name: 'Amanda Foster',
    role: 'Patient Coordinator',
    image: '/images/Team/amanda-foster.jpg',
    description: 'Dedicated to ensuring the best patient experience',
    activeProfile: true,
    specialization: "Patient Care & Treatment Coordination",
    qualifications: [
      "Healthcare Administration Degree",
      "Certified Patient Care Coordinator",
      "Medical Office Management Certification",
      "Customer Service Excellence Certification"
    ],
    experience: "7+ years in aesthetic medicine patient coordination",
    specialties: [
      "Treatment Planning",
      "Patient Education",
      "Care Coordination",
      "Insurance Navigation",
      "Patient Advocacy"
    ]
  }
]; 