import React from 'react';
import config from '../config';
import { 
  PhoneIcon, 
  EnvelopeIcon, 
  MapPinIcon,
  ClockIcon 
} from '@heroicons/react/24/outline';
import Map from '../components/Map';

const Contact = () => {
  const { company } = config;

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
          <div>
            <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
              Contact Information
            </h2>
            <div className="mt-3">
              <p className="text-lg text-gray-500">
                Get in touch with us for any questions or appointments.
              </p>
            </div>

            <div className="flex items-center gap-2 mt-4">
              <img src="/logo.png" alt="Logo" className="h-12 w-12" />
              <h3 className="text-2xl font-bold mb-4">{company.name}</h3>
              {/* <span className="text-lg font-semibold">Company Name</span> */}
            </div>

            <div className="mt-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <PhoneIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <p>{company.phone}</p>
                </div>
              </div>
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <EnvelopeIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <p>{company.email}</p>
                </div>
              </div>
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <MapPinIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <div className="space-y-0.5">
                    {company.address.split('|').map((line, index) => (
                      <p key={index} className="leading-tight">
                        {line.trim()}
                      </p>
                    ))}
                  </div>
                  <p>
                    {company.city} {company.zip}, {company.country}
                  </p>
                  <p className="mt-1">
                    <a 
                      href={company.maps.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-500"
                    >
                      View on Google Maps
                    </a>
                  </p>
                </div>
              </div>
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <ClockIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <p>Monday - Friday: {company.businessHours.monFri}</p>
                  <p>Saturday: {company.businessHours.sat}</p>
                  <p>Sunday: {company.businessHours.sun}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 md:mt-0">
            <div className="rounded-lg shadow-lg overflow-hidden h-[450px]">
              {/* <iframe
                title="Location Map"
                src={company.maps.embedUrl}
                width="100%"
                height="450"
                frameBorder="0"
                allowFullScreen=""
                loading="lazy"
              /> */}
                <Map />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact; 