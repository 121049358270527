import React, { useEffect, useState } from 'react'
import { AlertRec } from '../StdLib'
import axios from 'axios'


// const URL="http://wavelaz.kozow.com:8088/api/cats"

// const URL = "http://wavelaz.kozow.com:8088/api/"
// const URL = "http://bizapps.kozow.com:8088/api/"
// const URL2 = "http://192.168.10.88:8088/api/"




export default function LocalServerApiTesting() {
  const [URL, setURL] = useState("http://wavelaz.kozow.com:8088/api/items");
  // const [URL, setURL] = useState("https://wavelazapi.bizapps.pk/api/items");
  const [URL2, setURL2] = useState("https://wavelazapi.bizapps.pk/api/items/getfile/");


  const [IsLoading, setIsLoading] = useState(false);

  const [ShowImage, setShowImage] = useState('');
  const [ShowResult, setShowResult] = useState(false);

  const [RecAll, setRecAll] = useState([]);
  const [RecAllItems, setRecAllItems] = useState([]);

  const [RecAllURL2, setRecAllURL2] = useState([]);
  const [RecAllItemsURL2, setRecAllItemsURL2] = useState([]);

  useEffect(() => {

    // DataFetchAll()
    // DataFetchAllURL2()
  }, [])




  // ==================[  Fn: GET ALL RECORDS  ]=====================
  const DataFetchAll = async (e) => {
    setIsLoading(true)
    setShowResult(false)

    // // fetch(process.env.REACT_APP_API_URL + 'cats', { method: 'GET' })
    // // fetch(URL + 'cats', { method: 'GET' })
    // fetch(URL, { method: 'GET' })
    //   .then(res => res.json())
    //   .then(data => { setRecAll(data); setShowResult(true); setIsLoading(false) })

    //=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*

    try {
      // const result = await axios.get(process.env.REACT_APP_API_URL + `Procedures`)
      const result = await axios.get(URL)
      // console.log('*****************result: ', result); alert(result.data)                    

      console.log("\n\nRESPONSE=\n", result.data);
      setRecAll(result.data); 
      setShowResult(true);
      setIsLoading(false)

    } catch (error) {
      setIsLoading(false);
      console.log('ERROR in fetching...\n', error)

    } finally {
      setIsLoading(false);
    }


    // fetch(URL, { method: 'GET' })
    //   .then(res => res.json())
    //   .then(data => { setRecAllItems(data) })

    //=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*
  }

  // const DataFetchAllURL2 = async (e) => {

  //   // fetch(process.env.REACT_APP_API_URL + 'cats', { method: 'GET' })
  //   fetch(URL2, { method: 'GET' })
  //     .then(res => res.json())
  //     .then(data => { setRecAllURL2(data) })

  //   //=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*

  //   fetch(URL2, { method: 'GET' })
  //     .then(res => res.json())
  //     .then(data => { setRecAllItemsURL2(data) })

  //   //=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*
  // }

  return (
    <>
      <div className="py-[50px] px-2 grid grid-cols-2 gap-6 ">
        <div>
          <div className='w-full flex items-center gap-2'>
            <label className=" text-lg font-medium text-gray-700 ">
              API Curl:
            </label>
            <input
              type="text"
              name="URL"
              value={URL}
              onChange={(e) => setURL(e.target.value)}
              className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-accent focus:border-transparent"
              placeholder="http://"
            />
            <button onClick={() => DataFetchAll()} class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full border-b-4 border-blue-700 hover:border-blue-500 ">
            {IsLoading ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  
                </>
              ) : (
                'Fetch Data'
              )}
            </button>
          </div>

          {ShowResult &&
            <>
              From: {URL}<br />
              <pre>RecCount={RecAll.length}<br /> {JSON.stringify({ RecordsReceived: RecAll }, null, 2)}</pre>
            </>
          }
        </div>
        <div>
          <div className='w-full flex items-center gap-2'>
            <label className=" text-lg font-medium text-gray-700 ">
              API Curl:
            </label>
            <input
              type="text"
              name="URL2"
              value={URL2}
              onChange={(e) => setURL2(e.target.value)}
              className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-accent focus:border-transparent"
              placeholder="http://"
            />
            <button onClick={() => setShowImage(URL2)} class="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full border-b-4 border-purple-700 hover:border-purple-500 ">
                Fetch Image

            </button>
          </div>

          Usage: http://wavelaz.kozow.com:8088/api/Items/GetFile/Lotepred-T240307130914275.png <br/>
          Usage: https://wavelazapi.bizapps.pk/api/Items/GetFile/Lotepred-T240307130914275.png <br/>
          Usage: http://72.255.36.49:8088/api/Items/GetFile/Lotepred-T240307130914275.png <br/>
          {ShowImage &&
            <>
              From: {URL2}
              <div className=' ' style={{ width: '50%' }} >
                {/* <img src={URL2 + `Items/GetFile/${RecAllItemsURL2[0]?.PicURL}`} style={{ width: "100%", height: '100%', cursor: 'pointer' }} alt="..." /> */}
                <img src={ShowImage} style={{ width: "100%", height: '100%', cursor: 'pointer' }} alt="..." />
              </div>
            </>
          }
        </div>
      </div>
    </>
  )
}
