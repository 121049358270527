import React from 'react';

const Map = ({ address }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  return (
    // <div className="w-full h-[300px]">
      <iframe
        title="Aesthetic Solutions"
        // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3444.7772726972287!2d71.44412697606037!3d30.203445574909837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x393b33c498380c91%3A0x8c8c82ff0ac9a4a5!2sAhsan%20Medicine%20Company!5e0!3m2!1sen!2s!4v1708601234567!5m2!1sen!2s"
        src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Aesthetics Solutions canal road iqbal lahore&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    // </div>
  );
};

export default Map; 