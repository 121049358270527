import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const BookingCTA = ({ text = "Book Consultation", className = "", onClick }) => {
  const buttonClasses = `relative overflow-hidden bg-gradient-to-r from-blue-600 via-blue-500 to-blue-600 
                        text-white py-3 px-6 rounded-lg font-semibold
                        hover:from-blue-700 hover:via-blue-600 hover:to-blue-700
                        transform transition-all duration-300 shadow-md
                        ${className.includes('w-full') ? 'w-full' : 'inline-block'}
                        ${className}`;

  if (onClick) {
    return (
      <motion.button
        className={buttonClasses}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onClick}
      >
        {text}
      </motion.button>
    );
  }

  return (
    <Link to="/booking" className={className.includes('w-full') ? 'w-full block' : 'inline-block'}>
      <motion.div
        className={buttonClasses}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {text}
      </motion.div>
    </Link>
  );
};

export default BookingCTA; 