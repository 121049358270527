import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const DoctorProfileModal = ({ doctor, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          onClick={e => e.stopPropagation()}
          className="bg-white rounded-xl shadow-2xl max-w-2xl w-full overflow-hidden"
        >
          {/* Header with Image */}
          <div className="relative h-48 bg-gradient-to-r from-blue-600 to-blue-400">
            <img
              src={doctor.image}
              alt={doctor.name}
              className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 w-32 h-32 rounded-full border-4 border-white object-cover"
            />
          </div>

          {/* Content */}
          <div className="pt-20 px-6 pb-6">
            <h2 className="text-2xl font-bold text-center text-gray-900 mb-2">{doctor.name}</h2>
            <p className="text-accent text-center mb-4">{doctor.specialization}</p>
            
            <div className="space-y-4">
              <div>
                <h3 className="font-semibold text-gray-900 mb-2">Qualifications</h3>
                <ul className="list-disc pl-5 text-gray-600">
                  {doctor.qualifications?.map((qual, index) => (
                    <li key={index}>{qual}</li>
                  ))}
                </ul>
              </div>

              <div>
                <h3 className="font-semibold text-gray-900 mb-2">Experience</h3>
                <p className="text-gray-600">{doctor.experience}</p>
              </div>

              <div>
                <h3 className="font-semibold text-gray-900 mb-2">Specialties</h3>
                <div className="flex flex-wrap gap-2">
                  {doctor.specialties?.map((specialty, index) => (
                    <span 
                      key={index}
                      className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm"
                    >
                      {specialty}
                    </span>
                  ))}
                </div>
              </div>
            </div>

            {/* Close Button */}
            <button
              onClick={onClose}
              className="mt-6 w-full bg-accent text-white py-2 rounded-lg hover:bg-accent-dark transition-colors"
            >
              Close
            </button>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default DoctorProfileModal; 