import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const BookNowButton = ({ className = '', service = null }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/booking', { 
      state: { selectedService: service } 
    });
  };

  return (
    <motion.button
      onClick={handleClick}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className={`px-6 py-2 bg-accent text-white rounded-lg hover:bg-accent-dark transition-colors ${className}`}
    >
      Book Now
    </motion.button>
  );
};

export default BookNowButton; 