import { useState, useEffect } from 'react';
import { services as initialServices } from '../data/services';
import config from '../config';

export const useServices = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const itemsPerPage = config.api.itemsPerPage;

  useEffect(() => {
    const loadServices = async () => {
      try {
        // console.log('Initial services data:', initialServices); // Debug log
        
        // No need for API simulation since we're using local data
        setServices(initialServices);
        setLoading(false);
      } catch (err) {
        console.error('Error loading services:', err);
        setError('Error loading services');
        setLoading(false);
      }
    };

    loadServices();
  }, []);

  return { services, loading, error };
}; 

// ============================================================================
//   [ To Fetch Services from /API ]
// ============================================================================
// import { useState, useEffect } from 'react';
// import { getServices } from '../services/api';

// export const useServices = () => {
//   const [services, setServices] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchServices = async () => {
//       try {
//         const data = await getServices();
//         setServices(data);
//         setError(null);
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchServices();
//   }, []);

//   return { services, loading, error };
// }; 