import React from 'react';
import { motion } from 'framer-motion';
import Team from '../components/Team';
import WhyChooseUs from '../components/WhyChooseUs';

const About = () => {
  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="relative h-[50vh] bg-gradient-to-r from-blue-600 to-blue-400">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative container mx-auto px-4 h-full flex items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-white"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-4">About Our Clinic</h1>
            <p className="text-xl md:text-2xl">Excellence in Aesthetic Medicine</p>
          </motion.div>
        </div>
      </div>

      {/* Mission Statement */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="max-w-3xl mx-auto text-center"
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-6 text-gray-800">Our Mission</h2>
            <p className="text-lg text-gray-600 leading-relaxed">
              We are committed to providing the highest quality aesthetic treatments using cutting-edge technology 
              and techniques. Our team of experienced professionals ensures safe, effective, and personalized 
              solutions for all our clients.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <WhyChooseUs />

      {/* Team Section */}
      <Team />

      {/* Certifications Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-gray-800">
            Our Certifications & Accreditations
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {/* Add certification logos/content here */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default About; 