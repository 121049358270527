import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { services } from '../data/services';
import BookingCTA from '../components/BookingCTA';
import CategoryFilter from '../components/StaticSectionServices/CategoryFilter';
import SearchBar from '../components/StaticSectionServices/SearchBar';

const ServiceItem = ({ service, index }) => {
  const slug = service.title.toLowerCase().replace(/[^a-z0-9]+/g, '-');
  // alert(`\pages\services\ServiceItem slug or id ${slug}`)

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="group bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-2xl transform transition-all duration-300 hover:-translate-y-2 hover:bg-gray-50/80"
    >
      <div className="relative h-64 overflow-hidden">
        <img
          src={service.image}
          alt={service.title}
          className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-700"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        {service.tag && (
          <div className="absolute top-4 right-4">
            <span className="bg-white/80 text-accent px-4 py-2 rounded-full text-sm font-medium shadow-lg transform -translate-y-2 opacity-0 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-300">
              {service.tag}
            </span>
          </div>
        )}
      </div>

      <div className="p-6">
        <h3 className="text-2xl font-bold mb-3 text-gray-900 group-hover:text-accent transition-colors">
          {service.title}
        </h3>
        <p className="text-gray-600 mb-6 line-clamp-3">
          {service.description}
        </p>
        
        <div className="flex justify-between items-center gap-4">
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="flex-1"
          >
            <Link
              // to={`/services/${slug}`}
              to={`/services/${service.id}`}
              className="inline-block w-full text-center px-4 py-2 bg-white border-2 border-accent text-accent rounded-lg font-semibold 
                hover:bg-gradient-to-r hover:from-accent hover:to-accent-light
                hover:border-transparent hover:text-blue-600
                transform transition-all duration-300 ease-in-out hover:shadow-lg"
            >
              Discover Benefits
            </Link>
          </motion.div>
          
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="flex-1"
          >
            <Link
              to="/booking"
              className="inline-block w-full text-center px-4 py-2 bg-accent text-white rounded-lg font-semibold 
                hover:bg-accent-dark
                transform transition-all duration-300 shadow-lg hover:shadow-xl"
            >
              Book Now
            </Link>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

const Services = () => {
  const [filteredServices, setFilteredServices] = useState(services);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');

  // alert(`\pages\Services`)
  
  // Handle category change
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    let result = services;

    if (selectedCategory !== 'All') {
      result = result.filter(service => service.category === selectedCategory);
    }

    if (searchQuery.trim()) {
      const query = searchQuery.toLowerCase();
      result = result.filter(service =>
        service.title.toLowerCase().includes(query) ||
        service.description.toLowerCase().includes(query) ||
        service.tag.toLowerCase().includes(query)
      );
    }

    setFilteredServices(result);
  }, [selectedCategory, searchQuery]);

  return (
    <div className="min-h-screen bg-white">
      {/* Services Hero */}
      <div className="relative h-[50vh] bg-gradient-to-r from-blue-600 to-blue-400">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative container mx-auto px-4 h-full flex items-center">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-white"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-4">Our Services</h1>
            <p className="text-xl md:text-2xl">Discover our range of aesthetic treatments</p>
          </motion.div>
        </div>
      </div>

      {/* Filters Section */}
      <section className="py-8 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
            <CategoryFilter 
              selectedCategory={selectedCategory}
              onCategoryChange={handleCategoryChange}
            />
            <div className="w-full md:w-auto">
              <SearchBar 
                value={searchQuery}
                onChange={setSearchQuery}
                placeholder="Search services..."
              />
            </div>
          </div>
        </div>
      </section>

      {/* Services Grid */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredServices.map((service, index) => (
              <ServiceItem 
                key={service.id}
                service={service}
                index={index}
              />
            ))}
          </div>
          
          {/* No results message */}
          {filteredServices.length === 0 && (
            <div className="text-center py-8">
              <p className="text-gray-600 text-lg">
                No services found matching your criteria.
              </p>
            </div>
          )}
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-gray-800">Ready to Transform?</h2>
          <p className="text-lg text-gray-600 mb-8">
            Book your consultation today and let us help you achieve your aesthetic goals.
          </p>
          <div className="inline-block">
            <BookingCTA text="Book Your Consultation" className="text-lg px-8 py-4" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services; 