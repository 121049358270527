import React from 'react';
import { categories } from '../../data/services';

const CategoryFilter = ({ selectedCategory, onCategoryChange }) => {
  // Debug log
  console.log('Available categories:', categories);
  
  const handleCategoryClick = (category) => {
    if (onCategoryChange) {
      onCategoryChange(category);
    }
  };

  return (
    <div className="flex flex-wrap gap-2">
      {categories.map((category) => (
        <button
          key={category}
          onClick={() => handleCategoryClick(category)}
          className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 
            ${selectedCategory === category
              ? 'bg-blue-600 text-white'
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
        >
          {category}
        </button>
      ))}
    </div>
  );
};

export default CategoryFilter; 