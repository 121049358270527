import { motion } from 'framer-motion';

const features = [
  {
    icon: "/images/icons/tech.svg",
    title: 'Advanced Technology',
    description: 'Using the latest innovations in aesthetic treatments for optimal results'
  },
  {
    icon: "/images/icons/team.svg",
    title: 'Professional Team',
    description: 'Highly qualified specialists with extensive experience in aesthetics'
  },
  {
    icon: "/images/icons/safety.svg",
    title: 'Safety Standards',
    description: 'Adhering to the highest safety protocols and industry standards'
  },
  {
    icon: "/images/icons/care.svg",
    title: 'Personalized Care',
    description: 'Customized treatment plans tailored to your unique needs'
  }
];

const Features = () => {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Our Features</h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Experience excellence in aesthetic care with our premium features
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow group"
            >
              <div className="mb-6 transform group-hover:scale-110 transition-transform duration-300">
                <img src={feature.icon} alt={feature.title} className="w-16 h-16 mx-auto" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-3 group-hover:text-accent transition-colors">
                {feature.title}
              </h3>
              <p className="text-gray-600">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features; 