import React from 'react';
import { motion } from 'framer-motion';
import BookingCTA from './BookingCTA';

const BookingCTASection = () => {
  return (
    <section className="relative py-20 bg-gradient-to-r from-gray-50 to-white">
      {/* Optional: Add background image */}
      <div className="absolute inset-0 bg-cover bg-center bg-no-repeat opacity-10"
           style={{ backgroundImage: 'url(/images/booking-bg.jpg)' }} />
      
      <div className="container relative mx-auto px-4 text-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="max-w-4xl mx-auto"
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6 text-gray-800">
            Transform Your Look Today
          </h2>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            Book your consultation and start your journey to a more confident you.
          </p>
          <div className="inline-block">
            <BookingCTA 
              text="Book Your Consultation" 
              className="text-lg px-8 py-4 shadow-xl hover:shadow-2xl"
            />
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default BookingCTASection; 